<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";
import getMenu from "@/mixins/getMenu";

export default {
  name: "Reviews",

  metaInfo: { title: "Deja tu reseña!" },

  extends: View,

  mixins: [
    getMenu,
    LoadSections([
      "layout-header-menu",
      "public-review",
      "layout-footer-basic",
    ]),
  ],
};
</script>
